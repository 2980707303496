body { background-color: var(--clr-6556); }
body { color: var(--clr-6557); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-6559);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-6559);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
color: var(--clr-6559);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
h4 {
color: var(--clr-6557);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h5 {
color: var(--clr-6559);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
h6 {
color: var(--clr-6557);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-6559);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-6558);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-6560);
color: #ffffff;
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-6560);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-6560);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-6559);
color: #ffffff;
 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-6559);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-6559);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-6557);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-6557);
color: var(--clr-6556);
 }
.MES8 {
background-color: var(--clr-6557);
color: var(--clr-6556);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-6556);
 }
 }
a.MEC8 { color: var(--clr-6556);
&:hover { color: var(--clr-6559);}
 }
cite.MEC8{
color: var(--clr-6556);
}
/* Light:9 */
.MES9 {
background-color: var(--clr-6556);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-6556);
 }
.MES10 {
background-color: var(--clr-6556);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-6561);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-6561);
 }
.MES12 {
background-color: var(--clr-6561);
 }
/* Menu:13 */
.MES13 {
background-color: #ffffff;
color: var(--clr-6557);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 10px;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6556) transparent;
 }
.MES13 {
background-color: #ffffff;
color: var(--clr-6557);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 10px;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6556) transparent;
 }
a.MEC13 { color: var(--clr-6557);
&:hover { color: var(--clr-6557);}
 }
cite.MEC13{
color: var(--clr-6557);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* home block blue:14 */
.MES14 {
background-color: #ffffff;
color: var(--clr-6557);
border-width: 5px 1px 1px 1px;
border-style: solid;
border-color: var(--clr-6560) var(--clr-6556) var(--clr-6556) var(--clr-6556);
 }
.MES14 {
background-color: #ffffff;
color: var(--clr-6557);
border-width: 5px 1px 1px 1px;
border-style: solid;
border-color: var(--clr-6560) var(--clr-6556) var(--clr-6556) var(--clr-6556);
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: var(--clr-6557);
 }
 }
.MEC14 li {color: var(--clr-6557-flat);}
cite.MEC14{
color: var(--clr-6557);
}
/* :15 */
.MES15 {
background-color: #ffffff;
color: var(--clr-6557);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-6559) transparent transparent transparent;
 }
.MES15 {
background-color: #ffffff;
color: var(--clr-6557);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-6559) transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-6557);
 }
 }
.MEC15 li {color: var(--clr-6557-flat);}
cite.MEC15{
color: var(--clr-6557);
}
/* Hollaw:16 */
.MES16 {
color: #ffffff;
&:hover { color: #ffffff;}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* :17 */
.MES17 {
& .slider-arrow {color: var(--clr-6556);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slider-arrow:hover{color: #ffffff;
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: 2;
border-radius: 50%;
border-style:solid;
border-width:3px;
@media #{$medium-up} {
border-width: 3px;
};
@media #{$large-up} {
border-width: 3px;};
&:hover{border-color: 3;}
background-color: 20;
background-clip: padding-box;
&:hover {background-color: 1;}
width:5px;
height:5px;
@media #{$medium-up} {
width:5px;
height:5px;
};
@media #{$large-up} {
width:5px;
height:5px;
};
&:hover{background-color: 1;}
}}
 }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-6557);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-6557);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
a.MEC18 { color: var(--clr-6557);
&:hover { color: var(--clr-6557);}
 }
cite.MEC18{
color: var(--clr-6557);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-6557);
color: var(--clr-6556);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES20 {
background-color: var(--clr-6557);
color: var(--clr-6556);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-6556);
 }
 }
a.MEC20 { color: var(--clr-6556);
&:hover { color: var(--clr-6559);}
 }
cite.MEC20{
color: var(--clr-6556);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* News grid:21 */
.MES21 {
color: var(--clr-6557);
border-width: 1px;
border-style: solid;
border-color: var(--clr-6556);
 }
.MES21 {
color: var(--clr-6557);
border-width: 1px;
border-style: solid;
border-color: var(--clr-6556);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-6560);
 }
 }
cite.MEC21{
color: var(--clr-6557);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-6556);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-6560);
}
color: #ffffff;
& > article { color: #ffffff;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Main Menu:25 */
nav.me-Menu.MES25 {
& .menu-item.MEC25, & .menu-item.MEC25 > div.MEC25{ & > a.MEC25{color: var(--clr-6557);
font-size: 14px;
@media #{$large-up} {
font-size: 18.2px;
}text-transform: uppercase;
}
 &:hover > a.MEC25{color: var(--clr-6560);
}
 }
.menu-item.MEC25 { border-width: 15px;
border-style: solid;
border-color: #ffffff;
 }
&.horizontal > .menu-item.MEC25 { border:0;
border-color: #ffffff;
border-style: dotted;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC25 { border:0;
border-color: #ffffff;
border-style: dotted;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC25 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC25 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0;}

@media #{$medium-up} {
& > .menu-item > a{padding: 5px;}

}
@media #{$large-up} {
& > .menu-item > a{padding: 5px 10px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Slider Button:26 */
.MES26 {
background-color: var(--clr-6560);
&:hover {background-color: var(--clr-6562);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 20.8px;
};
padding: 15px 30px;

 }
/* Phone number:27 */
.MES27 {
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 25.6px;
};
 }
.MES27 {
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 25.6px;
};
 }
a.MEC27 { color: var(--clr-6560);
&:hover { color: var(--clr-6557);}
 }
cite.MEC27{
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 25.6px;
};
}
/* Shade 2:28 */
.MES28 {
background-color: var(--clr-6560);
 }
/* Shade 2:29 */
.MES29 {
background-color: var(--clr-6560);
 }
.MES29 {
background-color: var(--clr-6560);
 }
/* Shade 3:30 */
.MES30 {
background-color: var(--clr-6562);
 }
/* Shade 3:31 */
.MES31 {
background-color: var(--clr-6562);
 }
.MES31 {
background-color: var(--clr-6562);
 }
