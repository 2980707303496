@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

.b2top__wrapper {
  position: absolute;
  top: 110vh;
  right: 0.25rem;
  bottom: 0.5em;
  width: 3em;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 1024px) {
  .b2top__wrapper {
    display: none;
  }
}

a.b2top__button {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  position: sticky;
  pointer-events: all;
  bottom: 80px;
  width: 48px;
  height: 48px;
  padding: 4px;
  color: white;
  &:hover {
    color: #f2f2f2;
  }
}
